<template>
	<div>
		<searchCard>
			<div class="flex">
				<div class="w-1/5 mr-4">
					<h6>이름</h6>
					<b-form-input v-model.trim="opt.name" placeholder="이름" @keyup.enter="search" />
				</div>

				<div class="w-1/5 mr-4">
					<h6>이메일</h6>
					<b-form-input v-model.trim="opt.email" placeholder="이메일" @keyup.enter="search" />
				</div>

				<div class="w-1/5 mr-4">
					<h6>권한</h6>
					<vSelect v-model="opt.roleGroup" :clearable="false" :options="roleGroupKeyOpts" />
				</div>

				<div class="w-2/5">
					<h6 class="w-1/1">상태</h6>
					<vSelect
						class="w-1/2 inline-block"
						v-model="opt.status"
						:clearable="false"
						:options="statusKeyOpts"
					/>
					<b-button class="px-12 ml-4 -top-0.5" @click="search">검색</b-button>
				</div>
			</div>
		</searchCard>
		<b-card>
			<tb :inf="inf" :res="res" />
		</b-card>
		<adminPopup
			:pr="ths"
			:roleGroupKeyOpts="roleGroupKeyOpts.filter((v, i) => i > 0)"
			:statusKeyOpts="statusKeyOpts.filter((v, i) => i > 0)"
			cName="adminPopup"
		/>
	</div>
</template>

<script>
import adminPopup from 'pages/adminPopup'

export default {
	components: { adminPopup },
	data() {
		const roleGroupKeyOpts = [
				{ label: '전체', value: '' },
				{ label: '배송(발주) 관리', value: rule.delivery },
				{ label: '일반', value: rule.member },
				{ label: '핫티스트 담당', value: rule.manager },
				{ label: '핫티스트 담당(매체운영)', value: rule.hottistManager },
				{ label: '정산', value: rule.accounting },
				{ label: '어드민', value: rule.admin },
			],
			statusKeyOpts = [
				{ label: '전체', value: '' },
				{ label: '승인', value: 'ACTIVE' },
				{ label: '승인대기', value: 'WAIT' },
				{ label: '비활성', value: 'DEACTIVE' },
			],
			defOpt = {
				roleGroup: roleGroupKeyOpts[0],
				status: statusKeyOpts[0],
			}

		return {
			roleGroupKeyOpts,
			statusKeyOpts,
			opt: { ...defOpt },
			defOpt,
			inf: [
				{ title: 'No.', model: 'idx', size: 2 },
				{ title: '이름', key: 'name', size: 3 },
				{ title: '이메일', key: 'email', size: 6 },
				{ title: '상태', key: 'status', size: 4 },
				{ title: '권한', key: 'roleGroup', size: 4 },
				{ title: '등록일', key: 'registerDt', size: 6 },
				{ title: '승인일', key: 'confirmDt', size: 6 },
				{ title: '마지막수정일', key: 'updateDt', size: 6 },
				{
					title: '수정',
					model: 'button',
					icon: 'pencil-square',
					size: 2,
					func: item => {
						this.adminPopup.open(item)
					},
				},
			],
			lastOpt: {},
			res: {},
		}
	},
	methods: {
		search() {
			let data = {
				orderBy: getOrder('registerDt'),
				paging: getPaging(),
			}
			const opt = this.opt

			if (opt.name) data.name = opt.name
			if (opt.email) data.email = opt.email
			if (opt.roleGroup.value) data.roleGroup = opt.roleGroup.value
			if (opt.status.value) data.status = opt.status.value

			this.lastOpt = data
			this.changePage()
		},
		changePage(page = 1) {
			this.lastOpt.paging.pageNo = page - 1
			return postApi('/api/backoffice/adminSelect/adminList', this.lastOpt).then(res => {
				res.list = res.list.map(v => {
					v.roleGroup = getOptsFromValue(this.roleGroupKeyOpts, v.roleGroup).label
					v.status = getOptsFromValue(this.statusKeyOpts, v.status).label
					;['registerDt', 'confirmDt', 'updateDt'].map(k => (v[k] = v[k] ? v[k].substr(0, 10) : ''))
					return v
				})
				this.res = res
				return res
			})
		},
	},
	created() {
		this.search()
	},
}
</script>
