<template>
	<basePopup :okFunc="save" title="관리자 권한 수정">
		<div v-for="(v, i) in info" :key="v + i" class="flex-between">
			<p :class="'py-3'" v-text="v.label" />
			<b-form-input
				v-if="i < 2"
				class="w-4/5 top-1.5"
				v-model.trim="input[v.key]"
				:name="v.label"
				:placeholder="v.label + ' 입력해주세요'"
				:disabled="true"
			/>
			<vSelect
				v-else-if="i == 2"
				class="w-4/5 top-1.5"
				v-model="input.roleGroup"
				:clearable="false"
				:options="roleGroupKeyOpts"
			/>
			<vSelect v-else class="w-4/5 top-1.5" v-model="input.status" :clearable="false" :options="statusKeyOpts" />
		</div>
	</basePopup>
</template>

<script>
import basePopup from 'comp/local/basePopup'
export default {
	props: ['roleGroupKeyOpts', 'statusKeyOpts'],
	components: { basePopup },
	data() {
		const info = [
			{ label: '이름', key: 'name' },
			{ label: '이메일', key: 'email' },
			{ label: '권한', key: 'roleGroup', isDropdown: 1 },
			{ label: '상태', key: 'status', isDropdown: 1 },
		]
		return {
			isShow: false,
			seqNo: -1,
			info,
			input: {},
		}
	},
	methods: {
		open(item) {
			this.seqNo = item.seqNo

			this.input = {
				name: item.name,
				email: item.email,
				roleGroup: getOptsFromLabel(this.roleGroupKeyOpts, item.roleGroup),
				status: getOptsFromLabel(this.statusKeyOpts, item.status),
			}

			this.isShow = true
		},
		save() {
			putApi('/api/backoffice/adminHandle/updateAdmin', {
				seqNo: this.seqNo,
				roleGroup: getDropdownValue(this.input.roleGroup),
				status: getDropdownValue(this.input.status),
			}).then(() => {
				this.isShow = false
				alert.s('저장되었습니다.')
				this.p.changePage(this.p.lastOpt.paging.pageNo * 1 + 1)
			})
		},
	},
}
</script>
